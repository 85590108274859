@import '../../../../scss/theme-bootstrap';

.how-to-use {
  &__title {
    border-bottom: 1px solid $color-gray-border;
    font-family: $main-font;
    margin: 0;
    overflow: hidden;
    @include swap_direction(padding, 10px 30px 10px 10px);
    position: relative;
    cursor: pointer;
    @include breakpoint($medium-up) {
      padding: 10px;
    }
    .outer-wrap.active & {
      border-bottom: none;
    }
  }
  &__wrap {
    .outer-wrap.active {
      border-top: none;
      &:first-child {
        border-top: 1px solid $color-gray-border;
      }
      .view-details-button::before {
        top: 15%;
        margin-#{$rdirection}: 6px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .view-details-button {
      text-decoration: none;
      &::before {
        color: inherit;
        content: '\002B';
        display: inline-block;
        font-family: 'icons';
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        height: 100%;
        position: absolute;
        #{$rdirection}: 10px;
        top: 20%;
        text-rendering: optimizelegibility;
      }
    }
  }
  &__content {
    font-size: 13px;
    @include swap_direction(padding, 15px 40px 15px 10px);
    text-align: $ldirection;
    display: none;
    .outer-wrap.active & {
      display: block;
      border-bottom: 1px solid $color-gray-border;
    }
  }
  &__ingredients {
    display: none;
  }
}
